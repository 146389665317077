<template>
    <div class="page row">
        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex mb-3">
                        <b-form-group
                            class="select col-3 align-center mb-0 pl-0 search-campaign-inp"
                            label-for="input11"
                            label="Search Campaign"
                        >
                            <b-form-input size="sm" v-model="search" />
                        </b-form-group>
                    </div>
                    <div class="table-responsive">
                        <table class="table center-aligned-table">
                             <thead>
                                <tr>
                                    <th
                                        class="sortable"
                                        @click="
                                            (nameSort = !nameSort), sortable('name')
                                        "
                                    >Name</th>
                                    <th
                                        class="sortable"
                                        @click="
                                            (statusSort = !statusSort), sortable('status')
                                        "
                                    >Status</th>
                                    <th
                                        class="sortable"
                                        @click="
                                            (createdSort = !createdSort), sortable('created')
                                        "
                                    >Created</th>
                                    <th
                                        class="sortable"
                                        @click="
                                            (totalDnisSort = !totalDnisSort), sortable('total_dnis')
                                        "
                                    >Total number of calls</th>
                                    <th>Msg dropped</th>
                                    <th>Failed Drops</th>
                                    <th>Transaction</th>
                                    <th>Audio file</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="items">
                                <tr v-for="item in itemsSearch" :key="item.contact_id">
                                    <td>{{ item.name }}</td>
                                    <td>{{ getStatus(item.status) }}</td>
                                    <td>{{ getDate(item.created) }}</td>
                                    <td>{{ item.total_dnis }}</td>
                                    <td>{{ item.msg_dropped }}</td>
                                    <td>{{ item.total_dnis - item.msg_dropped }}</td>
                                    <td>{{ item.transaction }}</td>
                                    <td>{{ item.media_id }}</td>
                                    <td>
                                        <button class="btn btn-success" @click="loadCSV(item.id)">
                                            CSV
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            search: "",
            key: null,
            nameSort: false,
            statusSort: false,
            createdSort: false,
            totalDnisSort: false
        };
    },
    computed: {
        ...mapState(["items"]),
        itemsSearch() {
        let items = null;
        if (this.search !== "" && this.search.length > 1) {
            items = this.items.filter((el) => el.name.toLowerCase().includes(this.search.toLowerCase()));
        } else items = this.items;
        return items;
        },
    },
    async created() {
        await this.loadItems();
    },
    methods: {
        ...mapActions(["loadItems"]),
        getDate(date) {
            return this.$moment.unix(date).format("MMMM Do YYYY, hh:mm");
        },
        getStatus(status) {
            let value = null;
            switch (status) {
                case 0: {
                value = "Scheduled";
                break;
                }
                case 1: {
                value = "In progress";
                break;
                }
                case 2: {
                value = "Completed";
                break;
                }
                case 3: {
                value = "Interrupted";
                break;
                }
                case 4: {
                value = "Failed to start";
                break;
                }
            }
            return value;
        },
        sortable(key) {
            let itemsToSort = this.items
            this.key = key
            itemsToSort.sort(this.compare);
            this.$store.commit("updateItems", itemsToSort);
        },
        compare(a, b) {
            let type;
            if (this.key == 'name') { type = this.nameSort }
            if (this.key == 'status') { type = this.statusSort }
            if (this.key == 'created') { type = this.createdSort }
            if (this.key == 'total_dnis') { type = this.totalDnisSort }

            let item1 = a[this.key];
            let item2 = b[this.key];

            if (this.key == 'status') {
                item1 = this.getStatus(a[this.key]).toLowerCase()
                item2 = this.getStatus(b[this.key]).toLowerCase()
            }

            if (this.key == 'name') {
                item1 = item1.toLowerCase()
                item2 = item2.toLowerCase()
            }

            let comparison = 0;

            if (type) {
                if (item1 > item2) {
                comparison = 1;
                } else if (item1 < item2) {
                comparison = -1;
                }
            }else {
                if (item1 < item2) {
                comparison = 1;
                } else if (item1 > item2) {
                comparison = -1;
                }
            }

            return comparison;
        },
        loadCSV(id) {
            this.$axios({
                url: `/campaigns/calls/download/${id}`,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${id}-campaign.zip`); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.sortable {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    background-position: right center;
}
.btn {
    width: auto;
    padding: 5px 10px;
}
.search-campaign-inp {
    @media (max-width: 801px) {
        max-width: 30%;
        flex: 0 0 30%;
    }
    @media (max-width: 751px) {
        max-width: 50%;
        flex: 0 0 50%;
    }
    @media (max-width: 590px) {
        max-width: 70%;
        flex: 0 0 70%;
    }
    @media (max-width: 478px) {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
</style>
